import React, {Component} from "react";
import BookingInformationForm from "./BookingInformationForm";
import {connect} from "react-redux";
import BookingInformation from "./BookingInformation";
import SearchRecommendations from "./SearchRecommendations";

class Home extends Component {

    renderBody = () => {
        const { loading, isComplete } = this.props;
        if (isComplete && !loading) {
            return(
                <div>
                    <BookingInformation />
                    <SearchRecommendations history={this.props.history} />
                </div>
            )
        }
        else{
            return (
                <BookingInformationForm />
            )
        }
    };

    render() {
        return (
            <div className="Home row">
                <div className="container">
                    {this.renderBody()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ bookingInformationReducer }) => bookingInformationReducer;

export default connect(mapStateToProps)(Home);
