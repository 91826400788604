import React from "react";
import ApplicationUtil from "../../../../util/ApplicationUtil";
import moment from "moment";


const Segment = (props) => {
    let util = new ApplicationUtil();
    return (
        <tr>
            <td>{props.flightSegment.departureAirport.locationCode}</td>
            <td>{props.flightSegment.arrivalAirport.locationCode}</td>
            <td>
                {util.formatDateTimeToString(
                    moment(props.flightSegment.departureDateTime).subtract('5', 'hours')
                )}
            </td>
            <td>
                {util.formatDateTimeToString(
                    moment(props.flightSegment.arrivalDateTime).subtract('5', 'hours')
                )}
            </td>
            <td>{props.flightSegment.flightNumber}</td>
            <td>{props.flightSegment.marketingAirline.code}</td>
            <td>{props.flightSegment.resBookDesigCode}</td>
        </tr>
    )
};
export default Segment