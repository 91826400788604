import React, { Component } from 'react';
import PassengerTicket from './PassengerTicket';
import {connect} from "react-redux";
import { Redirect }  from "react-router-dom";
import aprovalImg from '../../assets/approval.png'
import ticketImg from '../../assets/ticket.png'


class Emission extends Component {
    onClick = () => (
        <Redirect to='/home'/>
    );

    render() {
        console.log(this.props);
        const { emissionRS , success } = this.props.reissueReducer;
        const { pnr } = this.props.bookingInformationReducer;
        if(!success) { return (<Redirect to='/home'/>)}
        return (
            <div className="EmissionResult row">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center success-emission">
                            <h4 className="Zeticketing-title">RESULTADO DE EMISIÓN</h4>
                            <img src={aprovalImg} alt=""/>
                            <p>¡Emisión completada!</p>
                            <p>PNR: <span><strong>{pnr}</strong></span></p>
                        </div>
                        <div className="col-8 mx-auto">
                            <div className="EmissionResult row container-box">
                                <div className="col-md-12">
                                    <h4>
                                        <img src={ticketImg} alt="" width="30"/>
                                        <strong>Tickets</strong>
                                    </h4>
                                    <hr/>
                                    <div className="PassengersInformation row">
                                        <div className="col-md-12">
                                            <table className="table table-condensed">
                                                <thead>
                                                <th>NOMBRES</th>
                                                {/*<th>APELLIDOS</th>*/}
                                                <th>NÚMERO DE TICKET</th>
                                                {/*<th>NÚMERO DE INVOICE</th>*/}
                                                </thead>
                                                <tbody>
                                                {
                                                    emissionRS.successAndWarningsAndAirReservation[0].ticketing.map((ticket) => {
                                                        if(!ticket){
                                                            return null
                                                        } else{
                                                            return (<PassengerTicket ticket={ticket} />)
                                                        }
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                            <div className="text-center">
                                                <button className="btn btn-success" onClick={this.onClick}>NUEVA REEMISIÓN</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps({ bookingInformationReducer, reissueReducer }){
    return { bookingInformationReducer, reissueReducer }
}

export default connect(mapStateToProps)(Emission);
