import React, {Component} from "react";
import {connect} from "react-redux";
import Recommendation from '@bit/codeorangex.minerva-ui.ui.recommendation';
import Filters from "../Filters";
import * as recommendationsActions from "../../../../actions/recommendationsActions";

class Recommendations extends Component {

    componentDidUpdate() {
        if(this.props.recommendationsInitial && this.props.recommendationsInitial.length > 0 && !this.props.isCalledToRenderFilters) {
            this.props.callToRenderFilters(true);
        }
    }

    render() {
        const { recommendations, isCalledToRenderFilters} = this.props;
        return (
            <div className="">
                {isCalledToRenderFilters? <Filters /> : null}
                <div className="SearchRecommendationsResult" style={{"padding-bottom": "116px;"}}>
                    {
                        recommendations.map((recommendation,i) => {
                            return (
                                <div className="Recommendation" key={i}>
                                    <div class="alert alert-primary row ml-0 mr-0 mb-0" role="alert">
                                        {recommendation.itinerary.map((option, index) => {
                                            return(
                                                <div className="col">Tarifa Tramo {index + 1} :<span style={{fontWeight: "bold"}}> {option.flights[0].brandedFare.description}</span></div>
                                            )
                                        })}
                                    </div>
                                    <Recommendation {...recommendation}
                                                    onChangeFlights={flights => console.log(flights)}
                                                    onContinue={() => this.props.history.push(`/recommendations/${i}`)} />
                                </div>

                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({recommendationsReducer}) => recommendationsReducer;

export default connect(
    mapStateToProps,
    recommendationsActions
)(Recommendations);
