import {LOADING_TRANSACTION, ON_RECEIBE_CONFIRMATION} from "../types/transactionTypes";


const initialState = {
    loading: false,
    success: false
};

export default function(state = initialState, action) {
    switch (action.type) {
        case LOADING_TRANSACTION:
            return { ...state, loading: true, success: false};
        case ON_RECEIBE_CONFIRMATION:
            return {
                ...state,
                loading: false,
                success: true
            };
        default:
            return state;
    }
}
