import React from 'react';
import AngleBooking from '../../../common/AngleBooking';
import passengerInfoIMG from '../../../../assets/passengers.png'

const showDocumentNumber = (document) => {
    switch (document.docType) {
        case "NI":
            return `DNI ${document.docID}`;
        case "PP":
            return `PP ${document.docID}`;
        case "FC":
            return `CE ${document.docID}`;
        case "RUC":
            return `RUC ${document.docID}`;
        default:
            return ` ${document.docID}`;
    }
};
const PassengersInformation = (props) => {

    const { travelerInfo: { airTraveler }, ticketingInfo} = props;
    return (
        <div className="PassengersInformation row">
            <div className="col-md-12">
                <div className="container-section-header">
                    <h3>
                        <img src={passengerInfoIMG} alt=""/> Pasajeros
                        <AngleBooking nameContent="passengers-content"/>
                    </h3>
                </div>
                <div id="passengers-content">
                    <table className="table" id="passenger">
                        <thead>
                        <tr>
                            <th>Nombres</th>
                            <th>Apellidos</th>
                            <th>Tipo</th>
                            <th>Documento</th>
                            <th>Ticket</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            airTraveler.map((customer, i) => {
                                return (
                                    <tr key={i} className="PassengerInformation">
                                        <td>{customer.personName.givenName}</td>
                                        <td>{customer.personName.surname}</td>
                                        <td>{customer.passengerTypeCode}</td>
                                        <td>
                                            {
                                                showDocumentNumber(customer.document[0])
                                            }
                                        </td>
                                        <td>
                                            {
                                                ticketingInfo[i].ticketDocumentNbr
                                            }
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )

};

export default PassengersInformation;


