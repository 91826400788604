import React, { Component } from "react";
import backpackLuggageIcon from "./icons/backpack_luggage.png";
import backpackLuggageDisabledIcon from "./icons/backpack_luggage_icon_disabled.png";
import handBaggageIcon from "./icons/hand_baggage.png";
import handBaggageDisabledIcon from "./icons/hand_baggage_icon_disabled.png";
import baggageIcon from "./icons/baggage_icon.png";
import baggageDisabledIcon from "./icons/baggage_icon_disabled.png";
import "./baggageWidget.scss";

class BaggageIndicator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDetails: false
        };
    }

    render = () => {
        const { showDetails } = this.state;
        const { handBaggage, checkedBaggage, personalBaggage } = this.props.baggage;

        return (
            <div
                className="baggage-indicator"
                onMouseEnter={() => this.setState({ showDetails: true })}
                onMouseLeave={() => this.setState({ showDetails: false })}
            >
                {showDetails ? (
                    <div className="baggage-indicator-popover pl-3 pr-3 shadow">
                        <div className="row">
                            <div className="col border-bottom pt-2 pb-2">
                                <div className="flex">
                                    <div
                                        className="baggage-indicator-icon mr-2 float-left"
                                        style={{ marginTop: "-7px" }}
                                    >
                                        <img
                                            src={
                                                personalBaggage && personalBaggage.pieces > 0
                                                    ? backpackLuggageIcon
                                                    : backpackLuggageDisabledIcon
                                            }
                                            alt="Baggage indicator icon"
                                        />
                                    </div>
                                    <span className="text-gray-800 text-sm float-right">
                                        {personalBaggage && personalBaggage.pieces > 0
                                            ? "Incluye articulo personal"
                                            : "No incluye articulo personal"}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col border-bottom pt-2 pb-2">
                                <div className="flex">
                                    <div
                                        className="baggage-indicator-icon mr-2 float-left"
                                        style={{ marginTop: "-9px" }}
                                    >
                                        <img
                                            src={
                                                handBaggage && handBaggage.pieces > 0
                                                    ? handBaggageIcon
                                                    : handBaggageDisabledIcon
                                            }
                                            alt="Baggage indicator icon"
                                        />
                                    </div>
                                    <span className="text-gray-800 text-sm float-right">
                                        {handBaggage && handBaggage.pieces > 0
                                            ? "Incluye equipaje de mano"
                                            : "No incluye equipaje de mano"}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col pt-2 pb-2">
                                <div className="flex">
                                    <div
                                        className="baggage-indicator-icon mr-2 float-left"
                                        style={{ marginTop: "-5px" }}
                                    >
                                        <img
                                            src={
                                                checkedBaggage && checkedBaggage.pieces > 0
                                                    ? baggageIcon
                                                    : baggageDisabledIcon
                                            }
                                            alt="Baggage indicator icon"
                                        />
                                    </div>
                                    <span className="text-gray-800 text-sm float-right">
                                        {checkedBaggage && checkedBaggage.pieces > 0
                                            ? `Incluye equipaje de bodega. ${
                                                checkedBaggage.pieces
                                            } pieza${
                                                checkedBaggage.pieces > 1 ? "s" : ""
                                            } por pasajero ${
                                                checkedBaggage.weight ? "de 23 kg" : ""
                                            }`
                                            : "No incluye equipaje de bodega"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className="baggage-indicator-icon">
                    <img 
                        src={
                            personalBaggage && personalBaggage.pieces > 0
                                    ? backpackLuggageIcon
                                    : backpackLuggageDisabledIcon
                            }
                             alt="Baggage indicator icon" 
                    />
                </div>
                <div className="baggage-indicator-icon">
                    <img
                        src={
                            handBaggage && handBaggage.pieces > 0
                                ? handBaggageIcon
                                : handBaggageDisabledIcon
                            }
                        alt="Baggage indicator icon"
                    />
                </div>
                <div className="baggage-indicator-icon">
                    <img
                        src={
                            checkedBaggage && checkedBaggage.pieces > 0
                                ? baggageIcon
                                : baggageDisabledIcon
                        }
                        alt="Baggage indicator icon"
                    />
                </div>
            </div>
        );
    };
}

export default BaggageIndicator;