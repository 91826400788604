const getBookingInformationRQ = (data) => {
    return {
        "pos" : {
            "source" : [ {
                "pseudoCityCode" : "47J7", //EN VANO
                "bookingChannel": {
                    "companyName" : {
                        "code": `${data.gds}`
                    }
                },
                agentSine: `${data.signature ? data.signature : ''}`,
                agentDutyCode: `${data.email ? data.email : ''}`,
                terminalID: `${data.accountId ? data.accountId : ''}`,
            } ]
        },
        "uniqueID" : {
            "type" : "14",
            "id" : `${data.pnr}`,
            "idcontext": `${data.orderId ? data.orderId : ''}`,
        }
    }
};

export default getBookingInformationRQ;