import React, {Component} from "react";
import ApplicationUtil from "../../../util/ApplicationUtil";
import AngleBooking from "../../common/AngleBooking";
import fareIMG from "../../../assets/fare.png";

class PricingInformation extends Component {
    render() {
        let totalAmount = 0;
        let util = new ApplicationUtil();
        const {
            ptcfareBreakdowns: {ptcfareBreakdown}
        } = this.props.priceInfo;

        return (
            <div className="PricingInformation row">
                <div className="col-md-12">
                    <div className="container-section-header">
                        <h3>
                            <img src={fareIMG} alt=""/> Tarifa
                            <AngleBooking nameContent="pricing-content"/>
                        </h3>
                    </div>
                    <div id="pricing-content">
                        <table className="table" id="fare">
                            <thead>
                            <tr>
                                <th>Tipo de Pasajero</th>
                                <th>Monto Base</th>
                                <th>Impuestos</th>
                                <th>Monto por Pasajero</th>
                                <th>Monto Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {ptcfareBreakdown.map((passengerType, i) => {
                                let total =
                                    passengerType.passengerFare[0].totalFare.amount *
                                    passengerType.passengerTypeQuantity.quantity;
                                totalAmount += total;

                                return (
                                    <tr key={i}>
                                        <td>
                                            {passengerType.passengerTypeQuantity.code}(
                                            {passengerType.passengerTypeQuantity.quantity})
                                        </td>
                                        <td>
                                            {passengerType.passengerFare[0].baseFare.amount.toFixed(
                                                2
                                            )}
                                        </td>
                                        <td>
                                            {passengerType.passengerFare[0].taxes.amount.toFixed(2)}
                                        </td>
                                        <td>
                                            {passengerType.passengerFare[0].totalFare.amount.toFixed(
                                                2
                                            )}
                                        </td>
                                        <td>{total.toFixed(2)}</td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td colSpan="4">Total</td>
                                <td id="totalAmount">
                                    {util.decimalFormat(totalAmount.toFixed(2))}
                                    <input
                                        type="hidden"
                                        id="PricingInformation-total-amount"
                                        defaultValue={totalAmount.toFixed(2)}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default PricingInformation;
