const buildFlightSegment = (origin, destination, date, airline, rph, resBookDesigCode) => {
    let flightSegments = [];
    const flightSegment = {
        "departureAirport": { "locationCode": origin },
        "arrivalAirport": { "locationCode": destination },
        "equipment": [],
        "departureDateTime": date.format(),
        "marketingAirline": { "code": airline },
        "bookingClassAvails": [{
            "bookingClassAvail": [{
                "resBookDesigCode" :resBookDesigCode
            }]
        }],
        "comment": [],
        "stopLocation": [],
        "rph": rph
    };
    flightSegments.push(flightSegment);
    return flightSegments;
};

export default buildFlightSegment;