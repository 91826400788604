module.exports = (id) => {
    return {
        "pos" : {
            "source" : [ {
                "terminalID": "",
                "isocountry" : "PE"
            } ]
        },
        "uniqueID" : {
            "type" : "21",
            "id" : `${id}`
        }
    }
};