const data = () => {
    return {
        "successAndWarningsAndAirReservation": [
            {},
            {
                "ticketing": [
                    {
                        "totalFare": {
                            "amount": 16.0
                        },
                        "travelerRefNumber": [
                            "MICHAELDELGADO"
                        ],
                        "pseudoCityCode": "4DA9",
                        "ticketDocumentNbr": "5445863829464"
                    }
                ]
            },
            {},
            {
                "ticketing": [
                    {
                        "totalFare": {
                            "amount": 16.0
                        },
                        "travelerRefNumber": [
                            "LUISDELGADO"
                        ],
                        "pseudoCityCode": "4DA9",
                        "ticketDocumentNbr": "5445863829465"
                    }
                ]
            }
        ]
    }
};
export default data;