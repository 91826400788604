import React, { Component } from 'react';
import * as recommendationsActions from "../../../../actions/recommendationsActions";
import { connect } from 'react-redux';

class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            ruc: "",
            feeAmount: "",
            isRUCChecked: false,
            isFEEChecked: false
        }
    }

    componentDidMount() {
        if(this.props.params && this.props.params.email) {
            const email = this.props.params.email;
            this.setState({ email: email}, () => this.props.onChangeEmail(email));
        }
    }

    render() {
        return (
            <div className="row ml-0 mr-0 mt-3 mb-3 bg-white pt-3 pb-3">
                <div className="col-12 container-section-header pt-3">
                    <h3>Datos de contacto</h3>
                </div>
                <div className='col-md-4 d-flex'>
                    <label className='mr-2 mt-auto mb-auto'>Correo</label>
                    <input type="text"
                        className="form-control"
                        value={this.state.email}
                        onChange={(e) => {
                            const value = e.target.value;
                            this.setState({
                                email: value
                            }, () => this.props.onChangeEmail(value))
                        }
                        }
                    />
                </div>

                <div className="col-md-2 d-flex">
                    <label className='mr-2 mt-auto mb-auto'>¿Agregar RUC?</label>
                    <input
                    type="checkbox"
                    defaultChecked={this.state.isRUCChecked}
                    onChange={e => {
                        const value = e.target.checked;
                        this.setState(
                          {
                            isRUCChecked: value
                          }, () => {
                            this.props.onChangeHasRUC(value);
                          }
                        );
                    }}
                  />
                </div>
                
                {this.state.isRUCChecked ? (
                    <div className='col-md-4 d-flex'>
                    <input type="text"
                        className="form-control"
                        placeholder="RUC"
                        value={this.state.ruc}
                        onChange={(e) => {
                            const value = e.target.value;
                            this.setState({
                                ruc: value
                            }, () => this.props.onChangeRUC(value))
                        }
                        }
                    />
                </div>
                ) : null}

                {
                    this.props.accountInformationReducer.accountInformation.profileType === "P" ?
                        (<div className='col-12 mt-3 d-flex'>
                            <div className="col-md-2 d-flex pl-0">
                                <label className='ml-0 mr-2 mt-auto mb-auto'>¿Agregar FEE?</label>
                                <input
                                    type="checkbox"
                                    defaultChecked={this.state.isFEEChecked}
                                    onChange={e => {
                                        const value = e.target.checked;
                                        this.setState(
                                            {
                                                isFEEChecked: value
                                            }, () => {
                                                this.props.onChangeHasFEE(value);
                                            }
                                        );
                                    }}
                                />
                            </div>
                            {this.state.isFEEChecked ? (
                                <div className='col-md-4 d-flex'>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Monto del fee"
                                        value={this.state.feeAmount}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            this.setState({
                                                feeAmount: value
                                            }, () => this.props.onChangeFEE(value))
                                        }
                                        }
                                    />
                                </div>
                            ) : null}
                        </div>) : null
                }

                              
                
            </div>
        );
    }
}

function mapStateToProps({
    recommendationsReducer, accountInformationReducer
}) {
    return {
        recommendationsReducer,
        accountInformationReducer
    };
}

export default connect(mapStateToProps, recommendationsActions)(ContactForm);
