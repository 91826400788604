import React, { Component } from "react";
import { connect } from "react-redux";
import SelectInput from "@bit/codeorangex.minerva-ui.ui.select-input";
import { ALL, ASCENDENT_SORTED_PRICE, DESCENDENT_SORTED_PRICE, FILTER_CLASS_INDEX, FILTER_CLASS_NAME, FILTER_PRICE_INDEX, FILTER_PRICE_NAME, FILTER_RATE_INDEX, FILTER_RATE_NAME } from "../../../../constants/filterConstants";
import * as recommendationsActions from "../../../../actions/recommendationsActions";
import filterIMG from "../../../../assets/filter.jpg";

class Filters extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedClass: ALL,
            selectedSortedPrice: ALL,
            selectedRate: ALL,
            filters: [
                { index: FILTER_CLASS_INDEX, name: FILTER_CLASS_NAME, value: ALL },
                { index: FILTER_PRICE_INDEX, name: FILTER_PRICE_NAME, value: ALL },
                { index: FILTER_RATE_INDEX, name: FILTER_RATE_NAME, value: ALL }
            ],
            bookingClassesOptions: [],
            sortedPriceOptions: [],
            rateOptions: []
        }
    }

    componentDidMount() {
        const { recommendationsReducer } = this.props;
        this.loadOptions(recommendationsReducer);
    }

    loadOptions(recommendationsReducer) {
        const bookingClassesOptions = this.getClassOptions(recommendationsReducer);
        const sortedPriceOptions = this.getSortedPriceOptions();
        const rateOptions = this.rateOptions(recommendationsReducer);
        this.setState({ 
            bookingClassesOptions: bookingClassesOptions, 
            sortedPriceOptions: sortedPriceOptions, 
            rateOptions: rateOptions
        });
    }

    getSortedPriceOptions() {
        const sortedPriceOptions = [
            { key: 1, value: ASCENDENT_SORTED_PRICE },
            { key: 2, value: DESCENDENT_SORTED_PRICE },     
            { key: 3, value: ALL }
        ]
        return sortedPriceOptions;
    }

    getClassOptions(recommendationsReducer) {
        const bookingClasses = []
        if (recommendationsReducer && recommendationsReducer.recommendationsInitial
            && recommendationsReducer.recommendationsInitial.length > 0) {
            recommendationsReducer.recommendationsInitial.reduce((counter, recommendation) => {
                recommendation.itinerary.forEach(option => {
                    let { bookingClass } = option.flights[0].segments[0];
                    let exitsBookingClass = bookingClasses.filter(
                        bc => bc === bookingClass
                    );
                    if (exitsBookingClass.length < 1) {
                        bookingClasses.push(bookingClass);
                    }
                });
            }, bookingClasses);
        }
        const options = bookingClasses.map((b, index) => {
            return {
                key: index,
                value: b
            }
        });
        options.push({
            key: options.length,
            value: ALL
        })
        return options;
    }

    rateOptions(recommendationsReducer) {
        const rateDescriptions = [];
        if (recommendationsReducer && recommendationsReducer.recommendationsInitial
            && recommendationsReducer.recommendationsInitial.length > 0) {
            recommendationsReducer.recommendationsInitial.reduce((counter, recommendation) => {
                recommendation.itinerary.forEach(option => {
                    option.flights.forEach(f => {
                        let { description } = f.brandedFare;
                        let exitsBookingClass = rateDescriptions.filter(
                            desc => desc === description
                        );
                        if (exitsBookingClass.length < 1) {
                            rateDescriptions.push(description);
                        }
                    })
                    
                }
            );
            }, rateDescriptions);
        }
        const rateOptions = rateDescriptions.map((b, index) => {
            return {
                key: index,
                value: b
            }
        });
        rateOptions.push({
            key: rateOptions.length,
            value: ALL
        })
        return rateOptions;
    }

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="container-box">
                        <div className="FlightEngine">
                            <div className="FlightEngine-header mb-1">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="container-section-header">
                                            <h3>
                                                <img src={filterIMG} alt="" className="filterImg"/> Filtros
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label className="font-semibold">Clase: </label>
                                        <SelectInput
                                            label={this.state.selectedClass}
                                            options={this.state.bookingClassesOptions}
                                            selectedKey={this.state.selectedClass}
                                            onChange={option => {
                                                let updateFilters = Array.from(this.state.filters);
                                                updateFilters[FILTER_CLASS_INDEX].value = option.value;
                                                this.setState({
                                                    ...this.state,
                                                    selectedClass: option.value,
                                                    filters: updateFilters
                                                }, () => {
                                                    this.props.filterByAllFilters(updateFilters);
                                                })
                                            }
                                            }
                                        />
                                    </div>
                                    <div className="col">
                                        <label className="font-semibold">Precio: </label>
                                        <SelectInput
                                            label={this.state.selectedSortedPrice}
                                            options={this.state.sortedPriceOptions}
                                            selectedKey={this.state.selectedSortedPrice}
                                            onChange={option => {
                                                let updateFilters = Array.from(this.state.filters);
                                                updateFilters[FILTER_PRICE_INDEX].value = option.value;
                                                this.setState({
                                                    ...this.state,
                                                    selectedSortedPrice: option.value,
                                                    filters: updateFilters
                                                }, () => {
                                                    this.props.filterByAllFilters(updateFilters);
                                                })
                                            }
                                            }
                                        />
                                    </div>
                                    <div className="col">
                                        <label className="font-semibold">Tarifa: </label>
                                        <SelectInput
                                            label={this.state.selectedRate}
                                            options={this.state.rateOptions}
                                            selectedKey={this.state.selectedRate}
                                            onChange={option => {
                                                let updateFilters = Array.from(this.state.filters);
                                                updateFilters[FILTER_RATE_INDEX].value = option.value;
                                                this.setState({
                                                    ...this.state,
                                                    selectedRate: option.value,
                                                    filters: updateFilters
                                                }, () => {
                                                    this.props.filterByAllFilters(updateFilters);
                                                })
                                            }
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ clear: "both" }}> </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps({
    recommendationsReducer
}) {
    return {
        recommendationsReducer
    };
}

export default connect(mapStateToProps, recommendationsActions)(Filters);
