import React from "react";
import itineraryImg from "../../../../assets/itinerary.png";
import AngleBooking from "../../../common/AngleBooking";
import Segment from "./Segment";
import { connect } from "react-redux";

const FlightInformation = props => {
  const {
      airItinerary: {
          originDestinationOptions: {
              originDestinationOption
          }
      }
  } = props;
  const flightSegments = [];
  originDestinationOption.forEach((originDestinationOption, i) => {
      originDestinationOption.flightSegment.forEach(s => {
          flightSegments.push(s);
      })
  });
  return (
    <div className="FlightInformation row">
      <div className="col-md-12">
        <div className="container-section-header">
          <h3 className="d-flex">
            <img src={itineraryImg} alt=""  className='mt-auto mb-auto'/> 
            <div className='mt-auto mb-auto ml-1'>Itinerario</div>
            {props.accountInformationReducer.accountInformation.profileType === "P" ? 
              <div className='ml-auto alert alert-info mb-0 mt-auto mb-auto'>{props.bookingInformationReducer.travelItineraryRS.pnr}</div>
              : null
            }
            
            <AngleBooking nameContent="itinerary-content" />
          </h3>
        </div>
        <div id="itinerary-content">
          <table className="table" id="itinerary">
            <thead>
              <tr>
                <th>Origen</th>
                <th>Destino</th>
                <th>Fecha salida</th>
                <th>Fecha llegada</th>
                <th>Número de vuelo</th>
                <th>Aerolínea</th>
                <th>Clase</th>
              </tr>
            </thead>
            <tbody>
            {

                flightSegments.map((flightSegment,i) => {
                    return ( <Segment flightSegment={flightSegment} key={i}/>)
                })
            }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps ({bookingInformationReducer, accountInformationReducer})  {
  return {
      bookingInformationReducer, accountInformationReducer
  }
}

export default connect(mapStateToProps, null)(FlightInformation)