import React from 'react';
import $ from 'jquery';

class AngleBooking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: false
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        let $content = $("#"+this.props.nameContent);
        if ($content.is(":visible")) {
            $content.slideUp("slow");
            this.setState({
                hidden: true
            });
        } else {
            $content.slideDown("slow");
            this.setState({
                hidden: false
            });
        }
    }

    render() {
        let icon = (this.state.hidden)?"fa fa fa-chevron-up pull-right":"fa fa-chevron-down pull-right";
        return (
            <i onClick={this.onClick} className={icon}> </i>
        )
    }
}

export default AngleBooking;