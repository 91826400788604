export const ON_RECEIVE_RECOMMENDATIONS = 'ON_RECEIVE_RECOMMENDATIONS';
export const ON_RECEIVE_NEGATIVE_FARES = 'ON_RECEIVE_NEFATIVE_FARES';
export const ON_FAIL_SEARCH_RECOMMENDATIONS = 'ON_FAIL_SEARCH_RECOMMENDATIONS';
export const LOADING = 'LOADING_SEARCH_RECOMMENDATIONS';
export const ON_CHANGE_FLIGHT_TYPE ='CHANGE_FLIGHT_TYPE';
export const FILTER_BY_ALL_FILTERS = 'FILTER_BY_ALL_FILTERS';
export const CALL_TO_RENDER_FILTERS = 'CALL_TO_RENDER_FILTERS';
export const ON_CHANGE_EMAIL = 'ON_CHANGE_EMAIL';
export const ON_CHANGE_RUC = 'ON_CHANGE_RUC';
export const ON_CHANGE_HAS_RUC = 'ON_CHANGE_HAS_RUC';
export const ON_FAIL_SEARCH_RECOMMENDATIONS_BY_EMAIL = 'ON_FAIL_SEARCH_RECOMMENDATIONS_BY_EMAIL';
export const ON_FAIL_SEARCH_RECOMMENDATIONS_BY_RUC = "ON_FAIL_SEARCH_RECOMMENDATIONS_BY_RUC";
export const ON_FAIL_SEARCH_RECOMMENDATIONS_BY_FEE = "ON_FAIL_SEARCH_RECOMMENDATIONS_BY_FEE";
export const ON_CHANGE_HAS_FEE = 'ON_CHANGE_HAS_FEE';
export const ON_CHANGE_FEE = 'ON_CHANGE_FEE';
export const ON_FAIL_BY_SERVER_RESPONSE = 'ON_FAIL_BY_SERVER_RESPONSE';