import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const Loading = (props) => (
    <div style={{color:'#4a5568'}}>
        <h3 className="text-center" style={{"fontSize":"16px"}}>
           <FontAwesomeIcon icon={faSpinner} spin/> {props.message}
        </h3>
    </div>

);

export default  Loading;