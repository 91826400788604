import {LOADING, ON_FAIL_REISSUE, ON_SUCCESS} from "../types/reissueTypes";
import {LOADING_TRANSACTION, ON_RECEIBE_CONFIRMATION} from "../types/transactionTypes";
import axios from "axios";
import getBookModifyRQ from "../transfer/reissueTransfer/getBookModifyRQ";
import res from "../util/mocks/ReissueJSON.json";


const URL = "https://reissue-service.zdev.tech/zreissue-service/v1/air/";
// const URL = "http://localhost:8080/v1/air/";
//const URL = "http://10.5.1.40:32772/zreissue-service/v1/air/"


export const reissueTicket = params => async (dispatch, getState) => {
    dispatch({
        type: LOADING
    });
    try {
        const { travelItineraryRS } = getState().bookingInformationReducer;
        const { recommendations , flightTypeOption} = getState().recommendationsReducer;
        
        const RQ = getBookModifyRQ(
            params,travelItineraryRS,
            recommendations[params.recommendationId],
            flightTypeOption);
        console.log("RQ - ReissueTicketing -->",RQ);
        const res = await axios.post(URL + "modify", RQ);
        console.log("RS - ReissueTicketing -->",res);
 
        if (res.data) {
            dispatch({
                type: ON_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: ON_FAIL_REISSUE,
                payload: { errorCode: 1001 }
            });
        }
    } catch (ex) {
        console.log(ex);
        dispatch({
            type: ON_FAIL_REISSUE,
            payload: { errorCode: 1001 }
        });
    }
};

export const addConcurrence = (pnr) => async (dispatch, getState) => {
    console.log("PNR add -->", pnr);
    dispatch({
        type: LOADING_TRANSACTION
    });
    try {
        const res = await axios.get(URL + "transaction/" + pnr);

        if (res.data) {
            dispatch({
                type: ON_RECEIBE_CONFIRMATION,
                payload: res.data
            });
        }
    } catch (ex) {
        console.log(ex);
    }
};