export const FILTER_CLASS_INDEX = 0;
export const FILTER_PRICE_INDEX = 1;
export const FILTER_RATE_INDEX = 2;

export const FILTER_CLASS_NAME = "filter_class_name";
export const FILTER_PRICE_NAME = "filter_price_name";
export const FILTER_RATE_NAME = "filter_rate_name";

export const ALL = "ALL";

export const ASCENDENT_SORTED_PRICE = "Menor precio";
export const DESCENDENT_SORTED_PRICE = "Mayor precio";