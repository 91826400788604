import React from "react";

const PriceSummary = (props) => {
    return(
        <div className="PriceInformation bg-white border mb-4">
            <div className="pt-3 pl-3 pr-3 pb-0">
                <div>
                    <div className="row ">
                        <div className="col pb-3 pt-2">
                            <div className="float-left font-secondary text-gray-800 text-xl font-medium">Tarifa por
                                pasajero
                            </div>
                            <div className="float-right font-secondary text-gray-800 text-xl font-medium">
                            </div>
                        </div>
                    </div>
                    {
                        props.pricing.items.map((item,i) => {
                            return (
                                <div className="row" key={i}>
                                    <div className="col pb-1">
                                        <div className="float-left font-secondary font-medium text-gray-800">{item.description}</div>
                                        <div className="float-right font-secondary font-medium text-gray-800">
                                            {item.amountText}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="row">
                        <div className="col mt-2 pt-3 pb-3 border-top">
                            <div className="float-left font-secondary text-gray-800 text-2xl font-medium">Total a
                                pagar
                            </div>
                            <div className="float-right font-secondary text-gray-800 text-2xl font-medium">
                                {props.pricing.finalItem.amountText}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default PriceSummary;