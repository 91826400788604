const getTravelItinerarySimplified = (travelItineraryRS) => {
    const {
        travelItinerary: {
            itineraryInfo: {
                reservationItems: {
                    item: {
                        0: {
                            air: {
                                detailedReservation: {
                                    airItinerary, priceInfo, travelerInfo, ticketing
                                }
                            }
                        }
                    }
                }
            }
        }
    } = travelItineraryRS;

    return {
        orderId: travelItineraryRS.travelItinerary.itineraryRef.idcontext,
        pnr: travelItineraryRS.travelItinerary.itineraryRef.id,
        accountId: travelItineraryRS.travelItinerary.customerInfos.customerInfo[0].profileRef.id,
        airItinerary,
        priceInfo,
        travelerInfo,
        ticketing
    }
};

export default getTravelItinerarySimplified;