module.exports = (otaProfileReadRS) => {
    const {
        profiles: {
            profileInfo: {
                0: {
                    uniqueID:{
                        0: {
                            id
                        } 
                    },
                    profile : {
                        companyInfo: {
                            companyName: {
                                0: {
                                    companyShortName
                                }
                            }
                        },
                        profileType, tpaextensions
                    }
                }
            }
        }
    } = otaProfileReadRS;

    return {
        accountID:  id,
        profileType: profileType,
        accountName: companyShortName,
        creditLimit: tpaextensions.any[0]
    }
};