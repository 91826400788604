import ApplicationUtil from "../../util/ApplicationUtil";
import uniqid from 'uniqid';

const getRecommendations = (data) => {
    const util = new ApplicationUtil();
    let recommendations = [];
    data.successAndWarningsAndPricedItineraries[0].pricedItinerary.forEach(
        (pricedItinerary, i) => {
            let itineraries = [];
            const COPA = "106";
            const IS_COPA = pricedItinerary.ticketingInfo && pricedItinerary.ticketingInfo.pricingSystem
                            && pricedItinerary.ticketingInfo.pricingSystem.code 
                            && pricedItinerary.ticketingInfo.pricingSystem.code === COPA ? true : false;

            pricedItinerary.airItinerary.originDestinationOptions.originDestinationOption.forEach(
                (originDestinationOption, index) => {
                    let flights = [];
                    let segments = [];
                    let originCode="";
                    let departureDateTime="";
                    let destinationCode="";
                    let arrivalDateTime="";
                    let airline="";
                    let airlineCode="";
                    let elapsed="";
                    let brandId="";
                    let brand="";
                    let flightNumber="";
                    let baggagePieces = "";
                    let handBaggagePieces = "";
                    let personalBaggagePieces = "";
                    originDestinationOption.flightSegment.forEach((s,i) => {
                        if (i === 0) {
                            originCode=s.departureAirport.locationCode;
                            departureDateTime = util.substractByGDS(s.departureDateTime, IS_COPA);
                            airlineCode=s.marketingAirline.code;
                            airline=s.operatingAirline.companyShortName;
                            elapsed= util.xmlToJson(s.tpaextensions.any[0]).elapsedTime;
                            elapsed = util.minutesToHours(elapsed);
                            flightNumber=s.flightNumber;
                            brand=util.xmlToJson(s.tpaextensions.any[1]).brandDescription;
                            brandId=util.xmlToJson(s.tpaextensions.any[2]).brandCode;
                            baggagePieces = util.xmlToJson(s.tpaextensions.any[3]).baggageInformationPieces;
                            // if(baggagePieces!=null && baggagePieces.length>0){
                            handBaggagePieces = util.xmlToJson(s.tpaextensions.any[4]).carryOnHandBaggageInformation;
                            personalBaggagePieces = util.xmlToJson(s.tpaextensions.any[5]).personalBaggageInformation;
                            // }
                            // console.log("baggagePieces ->", baggagePieces);
                            // console.log("handBaggagePieces ->",handBaggagePieces);
                            // console.log("personalBaggagePieces ->",personalBaggagePieces);
                        }
                        destinationCode=s.arrivalAirport.locationCode;
                        arrivalDateTime= util.substractByGDS(s.arrivalDateTime, IS_COPA);

                        let segment = {
                            departureAirport: {
                                code: s.departureAirport.locationCode,
                                name: s.departureAirport.locationCode
                            },
                            arrivalAirport: {
                                code: s.arrivalAirport.locationCode,
                                name: s.arrivalAirport.locationCode
                            },
                            departureDateTime: util.substractByGDS(s.departureDateTime, IS_COPA),
                            arrivalDateTime: util.substractByGDS(s.arrivalDateTime, IS_COPA),
                            elapsedTime: util.xmlToJson(s.tpaextensions.any[0]).elapsedTime,
                            flightNumber: s.flightNumber,
                            bookingClass: s.resBookDesigCode,
                            marketingAirline: {
                                code: s.marketingAirline.code,
                                name: ""
                            },
                            operatingAirline: {
                                code: s.operatingAirline.code,
                                name: s.operatingAirline.companyShortName
                            }
                        };
                        segments.push(segment);
                    });
                    let flight = {
                        id: uniqid(),
                        departureAirport: {
                            code: originCode,
                            name: originCode
                        },
                        arrivalAirport: {
                            code: destinationCode,
                            name: destinationCode
                        },
                        departureDateTime: departureDateTime,
                        arrivalDateTime: arrivalDateTime,
                        elapsedTime: elapsed,
                        baggage: {
                            handBaggage: { pieces: handBaggagePieces },
                            checkedBaggage: {
                                pieces: baggagePieces,
                                weight: ""
                            },
                            personalBaggage: { pieces: personalBaggagePieces }
                        },
                        brandedFare: {
                            brandID: brandId,
                            description: brand
                        },
                        airline: {
                            code: airlineCode,
                            name: airline
                        },
                        flightNumber: flightNumber,
                        segments: segments
                    };
                    flights.push(flight);
                    let itinerary = {
                        indicator: index,
                        flights: flights
                    };
                    itineraries.push(itinerary);
                }
            );

            let offerId = pricedItinerary.airItineraryPricingInfo.tpaextensions && pricedItinerary.airItineraryPricingInfo.tpaextensions.any ?
            util.xmlToJson(pricedItinerary.airItineraryPricingInfo.tpaextensions.any[0]) : "";
            let shoppingResponseRefID = pricedItinerary.airItineraryPricingInfo.tpaextensions && pricedItinerary.airItineraryPricingInfo.tpaextensions.any ?
            util.xmlToJson(pricedItinerary.airItineraryPricingInfo.tpaextensions.any[1]) : "";
            
            let base = pricedItinerary.airItineraryPricingInfo.fareInfos.fareInfo[0].fareInfo[0].fare.baseAmount;
            let taxes = pricedItinerary.airItineraryPricingInfo.fareInfos.fareInfo[0].fareInfo[0].fare.taxAmount;
            let total = pricedItinerary.airItineraryPricingInfo.fareInfos.fareInfo[0].fareInfo[0].fare.totalFare;
            let penality = pricedItinerary.airItineraryPricingInfo.fareInfos.fareInfo[0].fareInfo[0].fare.baseNUCAmount;
            let totalPay = total != null ? total : 0.0;
            totalPay = totalPay + penality;
            let recommendation = {
                id: i,
                itinerary: itineraries,
                pricing: {
                    headItem: {
                        description: "Total a pagar",
                        amountText: `US$ ${totalPay.toFixed(2)}`
                    },
                    items: [
                        { description: "Base", amountText:`US$ ${base}` },
                        { description: "Impuestos y tasas", amountText: `US$ ${taxes}`},
                        { description: "Penalidad", amountText: `US$ ${penality.toFixed(2)}`}
                    ],
                    finalItem: {
                        description: "Total",
                        amountText: `US$ ${totalPay.toFixed(2)}`
                    }
                }, 
                offerId: offerId,
                shoppingResponseRefID: shoppingResponseRefID
            };
            recommendations.push(recommendation);
        }
    );

    return recommendations;
};

export default getRecommendations;