import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Flight from "../Flight";
import moment from "moment";

const ItinerarySummary = (props) => {
    const {flightType,passengersNumber, origin, destination, itinerary} = props.itineraryDetails;
    const passengersNumText= passengersNumber > 1 ? `${passengersNumber} pasajeros.` : `${passengersNumber} pasajero.`;
    return (
        <div className="ItinerarySummary mb-5">
            <div className="row">
                <div className="col">
                    <h1 className="font-secondary text-4xl text-black font-bold">
                        {origin} <FontAwesomeIcon icon={faArrowRight}/> {destination}
                    </h1>
                    <p className="font-secondary text-lg text-gray-500 font-normal">
                        {/* {flightType}<span className="mr-2 ml-2">·</span>  {passengersNumText} */}
                    </p>
                </div>
            </div>
            {
                itinerary.map((itinerary,i) => {
                    const flight = itinerary.flights[0];
                    const flightDirectionString = itinerary.indicator === 0 ? "Vuelo de ida" : "Vuelo de retorno";
                    return (
                        <div className="ItineraryDetails" key={i}>
                            <div className="row">
                                <div className="col">
                                    <p className="mb-2 mt-3 font-secondary font-medium">{flightDirectionString}<span
                                        className="mr-2 ml-2">·</span>
                                        { moment(flight.departureDateTime).format("dddd, D MMMM YYYY")}
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Flight
                                        id={0}
                                        airline={flight.airline}
                                        departureDateTime={flight.departureDateTime}
                                        arrivalDateTime={flight.arrivalDateTime}
                                        elapsedTime={flight.elapsedTime}
                                        segments={flight.segments}
                                        baggage={flight.baggage}
                                        brandedFare={flight.brandedFare}
                                        recommendationId={""}
                                        indicator={flight.indicator}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            <div className="row">

            </div>


        </div>
    )
}

export default ItinerarySummary;