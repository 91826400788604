import {CHANGE_GDS, CHANGE_PNR, LOADING, ON_FAIL_BOOKING_INFO, ON_RECEIVE_BOOKING_INFO, ON_FAIL_PROVIDER_BOOKING_INFO} from "../types/bookingInformationTypes";

const initialState = {
    pnr: '',
    loading: false,
    travelItineraryRS: null,
    isComplete: false,
    gds: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE_PNR:
            return { ...state, pnr: action.payload };
        case LOADING:
            return { ...state, loading:true, errorMsg: '' };
        case ON_RECEIVE_BOOKING_INFO:
            return {
                ...state,
                loading: false,
                isComplete: true,
                travelItineraryRS: action.payload
            };
        case ON_FAIL_BOOKING_INFO:
            return { ...state, loading: false, isComplete: false,
                errorMsg: 'Error interno. Por favor comunicarse con el equipo de TI.'};
        case ON_FAIL_PROVIDER_BOOKING_INFO:
            return { ...state, loading: false, isComplete: false,
                errorMsg: 'Error del proveedor para obtener información de la reserva.'};
        case CHANGE_GDS:
            return {...state, gds: action.payload}
        default:
            return state;

    }

};