import axios from "axios";
import {
  LOADING,
  ON_RECEIVE_RECOMMENDATIONS, ON_RECEIVE_NEGATIVE_FARES,
  ON_FAIL_SEARCH_RECOMMENDATIONS, ON_CHANGE_FLIGHT_TYPE,
  FILTER_BY_ALL_FILTERS,
  CALL_TO_RENDER_FILTERS,
  ON_CHANGE_EMAIL,
  ON_CHANGE_RUC,
  ON_CHANGE_HAS_RUC,
  ON_CHANGE_HAS_FEE,
  ON_CHANGE_FEE,
  ON_FAIL_BY_SERVER_RESPONSE
} from "../types/searchRecommendationsTypes";
import getSearchRecommendationRQ  from "../transfer/recommendationTransfers/getSearchRecommendationRQ";
  // import res from "../util/mocks/RecommendationJSON.json";
 const URI = "https://reissue-service.zdev.tech/zreissue-service/v1/air/price";
// const URI = "http://localhost:8080/v1/air/price";
 //const URI = "http://10.5.1.40:32772/zreissue-service/v1/air/price";

export const searchRecommendations = params => async (dispatch, getState) => {
  dispatch({
    type: LOADING
  });
  try {
    const { travelItineraryRS } = getState().bookingInformationReducer;
    const searchRecommendationRQ = getSearchRecommendationRQ(params,travelItineraryRS);
    if(searchRecommendationRQ.error) {
      dispatch({
        type: searchRecommendationRQ.error,
        payload:  { errorCode: 1001 }
      })
    } else {
      const res = await axios.post(URI, searchRecommendationRQ);
      
    if (res.data) {
      if(res.data.errors != null){
        dispatch({
          type: ON_FAIL_BY_SERVER_RESPONSE,
          payload: res.data.errors
        });
      } else{
        dispatch({
          type: ON_RECEIVE_RECOMMENDATIONS,
          payload: res.data
        });
      }
    } else {
      dispatch({
        type: ON_FAIL_SEARCH_RECOMMENDATIONS,
        payload: { errorCode: 1001 }
      });
    }
    }
    
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: ON_FAIL_SEARCH_RECOMMENDATIONS,
      payload: { errorCode: 1001 }
    });
  }
};

export const onChangeFlightTypeOptions = (params)=> (dispatch) => {
  dispatch({
    type: ON_CHANGE_FLIGHT_TYPE,
    payload: params
  })
};

export const filterByAllFilters = (data) => async dispatch => {
  dispatch({
    type: FILTER_BY_ALL_FILTERS,
    payload: data
  });
}

export const callToRenderFilters = (data) => async dispatch => {
  dispatch({
    type: CALL_TO_RENDER_FILTERS,
    payload: data
  });
}

export const onChangeEmail = (params)=> (dispatch) => {
  dispatch({
    type: ON_CHANGE_EMAIL,
    payload: params
  });
};

export const onChangeRUC = (ruc) => (dispatch) => {
  dispatch({
    type: ON_CHANGE_RUC,
    payload: ruc
  });
}

export const onChangeHasRUC = (hasRUC) => (dispatch) => {
  dispatch({
    type: ON_CHANGE_HAS_RUC,
    payload: hasRUC
  });
}


export const onChangeHasFEE = (hasFEE) => (dispatch) => {
  dispatch({
    type: ON_CHANGE_HAS_FEE,
    payload: hasFEE
  });
}

export const onChangeFEE = (fee) => (dispatch) => {
  dispatch({
    type: ON_CHANGE_FEE,
    payload: fee
  });
}