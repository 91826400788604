import {
  LOADING,
  ON_FAIL_ACCOUNT_INFORMATION,
  ON_RECEIVE_ACCOUNT_INFORMATION
} from "../types/accountInformationTypes";

const initialState = {
  loading: false,
  accountInformation : {
    id: "",
    accountName : "",
    creditLimit : "",
    profileType : ""
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true, errorMsg: "" };
    case ON_RECEIVE_ACCOUNT_INFORMATION:
      return {
        ...state,
        loading: false,
        isComplete: true,
        accountInformation: action.payload
      };
    case ON_FAIL_ACCOUNT_INFORMATION:
      return {
        ...state,
        loading: false,
        isComplete: false,
        errorMsg: "Error interno. Por favor comunicarse con el equipo de TI."
      };
    default:
      return state;
  }
}
