import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

const Alert = (props) => (
    <div className="BookingInformation-error">
        <div className="alert alert-warning">
            <FontAwesomeIcon icon={faExclamationTriangle}/> {props.message}
        </div>
    </div>
);

export default  Alert;