import $ from 'jquery';
import moment from 'moment';

class ApplicationUtil {

    constructor() {
    }

    formatDateTimeToString(timestamp) {
        let date = new Date(timestamp);
        let options = {
            timeZone: "UTC",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit"
        };
        return date.toLocaleString('es-PE', options);
    }

    formatDateToString(timestamp) {
        let date = new Date(timestamp);
        let options = {
            timeZone: "UTC",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        };
        return date.toLocaleString('es-PE', options);
    }

    getMonthFromString(month) {
        let monthNumber = "";
        switch (month) {
            case "JAN": monthNumber = "1"; break;
            case "FEB": monthNumber = "2"; break;
            case "MAR": monthNumber = "3"; break;
            case "APR": monthNumber = "4"; break;
            case "MAY": monthNumber = "5"; break;
            case "JUN": monthNumber = "6"; break;
            case "JUL": monthNumber = "7"; break;
            case "AUG": monthNumber = "8"; break;
            case "SEP": monthNumber = "9"; break;
            case "OCT": monthNumber = "10"; break;
            case "NOV": monthNumber = "11"; break;
            case "DEC": monthNumber = "12"; break;
            default: break;
        }
        return monthNumber;
    }

    getMonthFromNumber(monthNumber) {
        let monthString = "";
        switch (monthNumber) {
            case "1": monthString = "JAN"; break;
            case "2": monthString = "FEB"; break;
            case "3": monthString = "MAR"; break;
            case "4": monthString = "APR"; break;
            case "5": monthString = "MAY"; break;
            case "6": monthString = "JUN"; break;
            case "7": monthString = "JUL"; break;
            case "8": monthString = "AUG"; break;
            case "9": monthString = "SEP"; break;
            case "10": monthString = "OCT"; break;
            case "11": monthString = "NOV"; break;
            case "12": monthString = "DEC"; break;
            default: break;
        }
        return monthString;
    }

    getFullYear(shortYear) {
        let currentYear = new Date().getFullYear()
        let fullYear = 0

        if (parseInt(shortYear) >= parseInt(currentYear.toString().substr(2,2))) {
            fullYear = parseInt("19" + shortYear)
        } else {
            fullYear = parseInt(currentYear.toString().substr(0,2) + shortYear)
        }

        return parseInt(fullYear);
    }

    xmlToJson(stringXml) {
        var obj = {};
        if(stringXml!=null && stringXml.length>0){
        var xmlDocument = $.parseXML(stringXml);
        if (xmlDocument.hasChildNodes()) {
            for (var i = 0; i < xmlDocument.childNodes.length; i++) {
                var item = xmlDocument.childNodes.item(i);
                var nodeName = item.nodeName.charAt(0).toLowerCase() + item.nodeName.substr(1, item.nodeName.length - 1);
                if (typeof (obj[nodeName]) == "undefined") {
                    obj[nodeName] = item.innerHTML;
                }
            }
        }
        }
        return obj;
    }

    localeString(x, separator, group) {
        var sx = (''+x).split('.'), s = '', i, j;
        separator || (separator = ' '); // default seperator
        group || group === 0 || (group = 3); // default grouping
        i = sx[0].length;
        while (i > group) {
            j = i - group;
            s = separator + sx[0].slice(j, i) + s;
            i = j;
        }
        s = sx[0].slice(0, i) + s;
        sx[0] = s;
        return sx.join('.')
    }

    decimalFormat(str) {
        let formatted = this.localeString(parseFloat(str), ",", 3);
        let decimal = formatted.split(".")[1];

        if (typeof decimal === 'undefined') {
            formatted += ".00";
        } else if (decimal.length === 1) {
            formatted += "0";
        }

        return formatted;
    }

    valid_credit_card(value) {
        // accept only digits, dashes or spaces
        if (/[^0-9-\s]+/.test(value)) return false;

        // The Luhn Algorithm. It's so pretty.
        var nCheck = 0, nDigit = 0, bEven = false;
        value = value.replace(/\D/g, "");

        for (var n = value.length - 1; n >= 0; n--) {
            var cDigit = value.charAt(n);
            nDigit = parseInt(cDigit, 10);

            if(bEven) {
                if ((nDigit *= 2) > 9) nDigit -= 9;
            }
            nCheck += nDigit;
            bEven = !bEven;
        }

        return (nCheck % 10) === 0;
    }

    GetCardType(number)  {
        // visa
        var re = new RegExp("^4");
        if (number.match(re) != null)
            return "VI";

        // Mastercard
        re = new RegExp("^5[1-5]");
        if (number.match(re) != null)
            return "CA";

        // AMEX
        re = new RegExp("^3[47]");
        if (number.match(re) != null)
            return "AX";

        // Discover
        re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
        if (number.match(re) != null)
            return "Discover";

        // Diners
        re = new RegExp("^36");
        if (number.match(re) != null)
            return "DC";

        // Diners - Carte Blanche
        re = new RegExp("^30[0-5]");
        if (number.match(re) != null)
            return "DC";

        // JCB
        re = new RegExp("^35(2[89]|[3-8][0-9])");
        if (number.match(re) != null)
            return "JCB";

        // Visa Electron
        re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
        if (number.match(re) != null)
            return "VI";

        return "";
    }

    minutesToHours(minutes){
        let result = "";
        let hours = minutes / 60

        if(hours.toString().indexOf(".")!=-1){
            let arr = hours.toString().split(".");
            if(arr[0].length==1){
                result+="0";
            }
            result += hours.toString().replace(".","");
        }

        return result;
    }

    substractByGDS(data, IS_COPA) {
        if (IS_COPA) {
            return moment(moment(data).subtract('5', "hours"));
        }
        return moment(moment(data));
    }
    
}

export default ApplicationUtil;
