import React, { Component } from 'react';

class Navbar extends Component {

    render () {

        return (
            <nav className="Navbar navbar navbar-default navbar-fixed-top">
                <div className="container">
                    <div className="navbar-header">
                        <a className="navbar-brand" href="/">
                            ReemisionesApp
                        </a>
                    </div>

                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav">
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }

}

export default Navbar;
