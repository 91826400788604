import React, {Component} from 'react';
import {connect} from "react-redux";
import * as bookingInformationActions from '../../../actions/bookingInformationActions';
import * as accountInformationActions from '../../../actions/accountInformationActions';
import FlightInformation from './FlightInformation';
import PassengersInformation from "./PassengersInformation";
import PricingInformation from './PricingInformation';
import ApplicationUtil from "../../../util/ApplicationUtil";
import fareIMG from "../../../assets/fare.png";
import AngleBooking from '../../common/AngleBooking';

class BookingInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            validation: {
                status: true,
                showMessage: false
            },
            message: "",
        };
    }

    componentDidMount(){
        let accountID = this.props.bookingInformationReducer.travelItineraryRS.accountId;
        this.props.accountInformation(accountID);
    }

    render() {
        const {
            travelItineraryRS: { airItinerary, priceInfo, travelerInfo, ticketing }
        } = this.props.bookingInformationReducer;      

        return (
            <div className="main">
                {this.renderAccountInformation()}
                <div className="row">
                    <div className="col-12">
                        <div className="container-box">
                            <div className="BookingInformation-complete">
                                <FlightInformation airItinerary={airItinerary} 
                                    bookingInformationReducer={this.props.bookingInformationReducer}
                                    accountInformationReducer={this.props.accountInformationReducer}
                                    />
                                <PassengersInformation travelerInfo={travelerInfo} ticketingInfo={ticketing} />
                                <PricingInformation priceInfo={priceInfo} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderAccountInformation = () => {
        console.log('in renderAccountInformation', this.props.accountInformationReducer);
        let util = new ApplicationUtil();
        let creditLimit = util.xmlToJson(this.props.accountInformationReducer.accountInformation.creditLimit);
        let profileType = this.props.accountInformationReducer.accountInformation.profileType;

        if (profileType=='B') {
            return(
                <div className="bg-white">
                    <div className="col-12">
                        <div className="container-section-header pt-3">
                            <h3 className='d-flex'>
                                <img src={fareIMG} alt="" style={{height: 'min-content'}} className='mt-auto mb-auto'/>
                                <div className='mt-auto mb-auto ml-1'>DATOS DE LA CUENTA</div>
                                <div className='ml-auto alert alert-info mb-0'>{this.props.bookingInformationReducer.travelItineraryRS.pnr}</div>
                                <AngleBooking nameContent="pricing-content" />
                            </h3>
                        </div>
                        <div>
                        <table className="table" id="fare">
                            <thead>
                            <tr>
                                <th>Nombre de agencia</th>
                                <th>ID</th>
                                <th>Límite de crédito</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{this.props.accountInformationReducer.accountInformation.accountName}</td>
                                    <td>{this.props.accountInformationReducer.accountInformation.accountID}</td>
                                    <td>$ {creditLimit.creditLimit}</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>

                    </div>
                </div>
            )
        }
    };
}

function mapStateToProps ({bookingInformationReducer, accountInformationReducer})  {
    return {
        bookingInformationReducer, accountInformationReducer
    }
}


export default connect(mapStateToProps, bookingInformationActions)(BookingInformation)

