import {
    LOADING,
    ON_FAIL_REISSUE,
    ON_SUCCESS
} from "../types/reissueTypes";

import getReult from '../transfer/reissueTransfer/emissionResul';

const initialState = {
    loading: false,
    emissionRS: null,
    errorMsg: "",
    success: false
};

export default function(state = initialState, action) {
    switch (action.type) {
        case LOADING:
            return { ...state, loading: true, errorMsg: "" };
        case ON_SUCCESS:
            return {
                ...state,
                loading: false,
                emissionRS: action.payload,
                success: true
            };
        case ON_FAIL_REISSUE:
            return {
                ...state,
                loading: false,
                errorMsg: "Error interno. Por favor comunicarse con el equipo de TI.",
                success: false
            };
        default:
            return state;
    }
}
