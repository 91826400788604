import { error } from 'jquery';
import { ON_FAIL_SEARCH_RECOMMENDATIONS_BY_EMAIL, ON_FAIL_SEARCH_RECOMMENDATIONS_BY_FEE, ON_FAIL_SEARCH_RECOMMENDATIONS_BY_RUC } from '../../types/searchRecommendationsTypes';
import buildFlightSegment from './buildFlightSegment';

const getSearchRecommendationRQ = (params, travelItineraryRS) => {
    if(params.email.trim() === "") {
        return {error: ON_FAIL_SEARCH_RECOMMENDATIONS_BY_EMAIL};
    }
    if(params.hasRUC && params.ruc.trim() === "") {
        return {error: ON_FAIL_SEARCH_RECOMMENDATIONS_BY_RUC}
    }
    if(params.hasFEE && (params.feeAmount === 0 || params.feeAmount.trim() === "")) {
        return { error: ON_FAIL_SEARCH_RECOMMENDATIONS_BY_FEE}
    }
    let originDestinationsOption = [];
    const airline = travelItineraryRS.airItinerary.originDestinationOptions
        .originDestinationOption[0].flightSegment[0].operatingAirline.code;
    const originRph = travelItineraryRS.airItinerary.originDestinationOptions
        .originDestinationOption[0].flightSegment[0].rph;
    const resBookDesigCode = travelItineraryRS.airItinerary.originDestinationOptions
        .originDestinationOption[0].flightSegment[0].resBookDesigCode;

    let returnRph = "";
    if(params.flightTypeOption !== "OW"){
        returnRph = travelItineraryRS.airItinerary.originDestinationOptions
            .originDestinationOption[1].flightSegment[0].rph;
    }
    //First flight
    if (params.flightTypeOption === "OW" || params.flightTypeOption === "RT") {
        const originDestinationOption = {
            "flightSegment": buildFlightSegment(params.origin, params.destination, params.startDate, airline, originRph, resBookDesigCode),
            "travelPurpose": "CHANGE"
        };
        originDestinationsOption.push(originDestinationOption);
    } else {
        const originDestinationOption = {
            "flightSegment": travelItineraryRS.airItinerary.originDestinationOptions.originDestinationOption[0].flightSegment,
            "travelPurpose": ""
        };
        originDestinationsOption.push(originDestinationOption);
    }
    if(returnRph != ""){
        //Return Flight
        if (params.flightTypeOption === "OR" || params.flightTypeOption === "RT") {
            const returnDate = params.flightTypeOption === "OR" ? params.startDate : params.endDate;
            const originDestinationOption = {
                "flightSegment": buildFlightSegment(params.destination, params.origin, returnDate, airline, returnRph),
                "travelPurpose": "CHANGE"
            };
            originDestinationsOption.push(originDestinationOption);

        } else {
            let flightSegment = travelItineraryRS.airItinerary.originDestinationOptions.originDestinationOption[0].flightSegment;
            if(travelItineraryRS.airItinerary.originDestinationOptions.originDestinationOption.length>1){
                flightSegment = travelItineraryRS.airItinerary.originDestinationOptions.originDestinationOption[1].flightSegment;
            }
            const originDestinationOption = {
                "flightSegment": flightSegment,
                "travelPurpose": ""
            };
            originDestinationsOption.push(originDestinationOption);
        }
    }

    let airTravelerAvails = [];
    travelItineraryRS.travelerInfo.airTraveler.forEach( traveler => {
        
        let ticketNumber = "";
        travelItineraryRS.ticketing.forEach(ticket => {
            if (ticket.travelerRefNumber[0] === traveler.travelerRefNumber.rph)
                ticketNumber = ticket.ticketDocumentNbr;
        });
        let document = `D${traveler.document[0].docID}`;
        let airTravelerAvail = {
            "passengerTypeQuantity": [],
            "airTraveler": {
                "personName": traveler.personName,
                "telephone": [],
                "email": [],
                "address": [],
                "custLoyalty": [],
                "document": [{ "docLimitations": [], "docID": document }],
                "travelerRefNumber": { 
                    "rph": traveler.travelerRefNumber.rph,
                    "surnameRefNumber": ticketNumber
                },
                "socialMediaInfo": [],
            }
        };
        airTravelerAvails.push(airTravelerAvail);
    });

    return {
        "pos": { "source": [{ "pseudoCityCode": travelItineraryRS.ticketing[0].pseudoCityCode, "erspuserID": travelItineraryRS.ticketing[0].pricingSystem.code, 
            "agentDutyCode": params.email}] }, // ingresar aqui el gd
        "airItinerary": {
            "originDestinationOptions": {
                "originDestinationOption": originDestinationsOption
            }
        },
        "travelerInfoSummary": {
            "seatsRequested": [],
            "airTravelerAvail": airTravelerAvails,
            "pricingPref": [],
            "paymentDetailType": {
                "paymentAmount": {
                    "amount": params.feeAmount
                }
            }
        },
        "bookingReferenceID": { "id": travelItineraryRS.pnr, "idcontext": travelItineraryRS.orderId, "companyName": { "value": params.ruc } },
        "flightReference": []
    }
};

export default getSearchRecommendationRQ;