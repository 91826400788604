import React, {Component} from "react";
import Navbar from "./components/common/Navbar";
import {connect} from 'react-redux';

export default function(ComposedComponent) {
    class Layout extends Component {
        render = () => {
            return (
                <div>
                    <Navbar pathName={""}/>
                    <div id="main-content">
                        <ComposedComponent {...this.props}/>
                    </div>
                    <div className="Footer">
                        <div className="container-fluid">
                            <p className="Footer-text">
                                
                            </p>
                        </div>
                    </div>
                </div>
            );
        };
    }
    const mapStateToProps = ({bookingInformationReducer}) => bookingInformationReducer;
    return connect(mapStateToProps, {})(Layout);
}

