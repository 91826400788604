import React, { Component, useState } from "react";
import {connect} from "react-redux";
import ItinerarySummary from "./ItinerarySummary";
import PriceSummary from "./PriceSummary";
import * as reissueActions from "../../actions/reissueActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Redirect} from "react-router-dom";
import Alert from "../common/Alert";
import Cards from "react-credit-cards";
import 'react-credit-cards/es/styles-compiled.css';
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    formatCardType,
    formatClearNumber
  } from '../Checkout/Payment/index';

class Checkout extends Component {

    constructor(props) {
        super(props);
        const recommendation = this.props.recommendationsReducer.recommendations[this.props.match.params.recommendationId];
        console.log(recommendation);
        console.log("Props indexReissue -->",props);
        this.state = {
            recommendation: recommendation,
            //fee: "",
            formOfPayment:"CASH",
            number:"",
            nameHolder:"",
            expiry:"",
            cvc:"",
            focus:"",
            issuer:"",
            itineraryDetails : {
                flightType: "",
                passengersNumber: "",
                origin: "",
                destination: "",
                itinerary: []
            },
            blockIssue: false
        };
    }

    // handleCallback = ({ issuer }, isValid) => {
    //     if (isValid) {
    //       this.setState({ issuer });
    //     }
    //   };

    handleInputFocus = (e) => {
        this.setState({ focus: e.target.name });
    }
      
    handleInputChange = (e) => {
        let { name, value } = e.target;  
        if (name === "number") {
            value = formatCreditCardNumber(value);
            this.setState({ issuer : formatCardType(value)});
          } else if (name === "expiry") {
            value = formatExpirationDate(value);
          } else if (name === "cvc") {
            value = formatCVC(value);
          }      
        this.setState({ [name]: value });
     }

    componentDidMount() {
        const { recommendation } = this.state;
        if (recommendation) {
            const flightType = recommendation.itinerary.lenght === 1 ? "Solo Ida" : "Ida y vuelta";
            const passengersNumber = "2";
            const origin = recommendation.itinerary[0].flights[0].departureAirport.name;
            const destination = recommendation.itinerary[0].flights[0].arrivalAirport.name;

            this.setState({
                itineraryDetails : {
                    flightType: flightType,
                    passengersNumber: passengersNumber,
                    origin: origin,
                    destination: destination,
                    itinerary: recommendation.itinerary
                }
            })
        }
    }
    onChangeInput = (e) => {
        e.preventDefault();
        const name = e.target.name;
        this.setState({
            [name]: e.target.value
        })
    };
    onReissueTicket = (e) => {
        e.preventDefault();
        const { recommendation } = this.state;
        //if (recommendation.pricing.items[0].amountText.includes("-") || recommendation.pricing.items[1].amountText.includes("-")){
        //    const { pnr } = this.props;
        //    this.props.addConcurrence(pnr);
        //    this.state.blockIssue = true;
       //}else {
            this.props.reissueTicket({
                recommendationId: this.props.match.params.recommendationId,
                formOfPayment: this.state.formOfPayment,
                paymentDetails: {
                    number: formatClearNumber(this.state.number),
                    nameHolder:this.state.nameHolder,
                    expiry: "20" + this.state.expiry.slice(2,4) + "-" + this.state.expiry.slice(0,2),
                    cvc:this.state.cvc,
                    issuer:this.state.issuer
                }
                //fee: this.state.fee
            });
        //}
    };

    showActions = () => {
        if (this.state.formOfPayment === "CC") {
            return this.showPaymentCC();
        }
        const { loading } = this.props.reissueReducer;
        if (loading) {
            return this.showButtonLoading();
        }
        return (
            <div className="col">
                {this.showButtonReissueEnable()}
            </div>
        )
    };

    showButtonLoading = () => {
        return (
            <div className="col">
                <button className="btn btn-success btn-block mb-5 disabled" disabled="disabled">
                    <FontAwesomeIcon icon={faSpinner} spin/> Reemitiendo
                    reserva...
                </button>
            </div>
        )
    };

    showButtonReissueEnableOrDisable = () => {
        if (!this.state.number || !this.state.nameHolder || !this.state.expiry || !this.state.cvc) {
            return(
                <button className="btn btn-success btn-block mb-5 disabled" disabled="disabled">Reemitir </button>
            );
        } else {
            const { loading } = this.props.reissueReducer;
            if (loading) {
                return this.showButtonLoading();
            } else {
                return this.showButtonReissueEnable();
            }
        }
    };

    showButtonReissueEnable = () => {
        return (
            <button id="btnSuccess" className="btn btn-success  btn-block mb-5" onClick={this.onReissueTicket}> Reemitir </button>
        );
    };

    showMessageNotPermitIssueNegativeFare = () => {
        return (
            <div className="container-section-header">
                <h2>
                    NO SE PUEDE REMITIR TARIFAS EN NEGATIVO!
                </h2>
                <label>Por favor contacte a su supervisor para continuar con la remisión</label>
            </div>
        );
    };

    showPaymentCC = () => {

        return (
            <div className="col">
                <Cards
                    cvc={this.state.cvc}
                    expiry={this.state.expiry}
                    focused={this.state.focus}
                    name={this.state.nameHolder}
                    number={this.state.number}
                />
                <br/>
                <form>
                    <input
                        type="tel"
                        name="number"
                        value={this.state.number}
                        placeholder="Numero de tarjeta"
                        pattern="[\d| ]{16,22}"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                    />
                    <input
                        type="text"
                        name="nameHolder"
                        value={this.state.nameHolder}
                        placeholder="Nombre del tarjeta habiente"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                    />
                    <input
                        type="text"
                        name="expiry"
                        value={this.state.expiry}
                        placeholder="MM/YY Expiracion"
                        pattern="\d\d/\d\d"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                    />
                    <input
                        type="tel"
                        name="cvc"
                        value={this.state.cvc}
                        placeholder="CVC"
                        pattern="\d{3,4}"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                    />
                    <input type="hidden" name="issuer" value={this.state.issuer} />
                </form>
                <br/>
                {this.showButtonReissueEnableOrDisable()}
            </div>
        );
    };

    render = () => {
        if (!this.state.recommendation) {
            return (<Redirect to='/home'/>)
        }
        const {itineraryDetails, recommendation: { pricing }} = this.state;

        const { success, errorMsg } = this.props.reissueReducer;

        if (success) {
            return (<Redirect to='/emission'/>)
        }
        return (
            <div className="Home row">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="BookingProgress pt-3 pb-3">
                                <div className="row">
                                    <ul className="col">
                                        <li className="float-left font-secondary cursor-default font-light text-gray-500">
                                            1. Selecciona tu vuelo
                                            <i className="fas fa-chevron-right text-sm mr-3 ml-3">
                                                {" "}
                                            </i>
                                        </li>
                                        <li className="float-left font-secondary cursor-default font-bold text-gray-800">
                                            2. Completa tus datos
                                            <i className="fas fa-chevron-right text-sm mr-3 ml-3">
                                                {" "}
                                            </i>
                                        </li>
                                        <li className="float-left font-secondary cursor-default font-light text-gray-500">
                                            3. Finaliza la reserva
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <ItinerarySummary itineraryDetails={itineraryDetails}/>
                            <div className="FormOfPayment bg-white border mb-4">
                                <div className="pt-3 pl-3 pr-3 pb-3">
                                    <div className="row form-row">
                                        <div className="col-3">
                                            <label>Forma de Pago</label>
                                            <select className="form-control" value={this.state.formOfPayment}
                                                    name="formOfPayment" onChange={this.onChangeInput}>
                                                <option value="CASH">CASH</option>
                                                <option value="CC">TARJETA DE CREDITO</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="col-md-4">
                            <PriceSummary pricing={pricing}/>
                            {/* < div className="row mb-4">
                                <div className="col form-items">
                                    <input type="text" className="form-control pt-2 pb-2 pl-3 pr-2 h-auto text-sm"
                                           placeholder="Ingrese Fee" value={this.state.fee} name="fee"
                                           onChange={this.onChangeInput}/>
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="col"> */}
                            {this.showActions()}
                        {/* </div> */}
                    </div>
                    { errorMsg ? <Alert message={errorMsg}/> : null}
                    { this.state.blockIssue ? this.showMessageNotPermitIssueNegativeFare() : null}
                </div>
            </div>
        );
    }
}

function mapStateToProps({ recommendationsReducer, reissueReducer, transactionReducer }){
    return { recommendationsReducer, reissueReducer, transactionReducer }
}

export default connect(mapStateToProps, reissueActions)(Checkout);
