import { combineReducers } from 'redux';
import bookingInformationReducer from "./bookingInformationReducer";
import recommendationsReducer from "./recommendationsReducer";
import accountInformationReducer from "./accountInformationReducer";
import reissueReducer from "./reissueReducer";
import transactionReducer from './transactionReducer';

const rootReducer = combineReducers({
    bookingInformationReducer,
    recommendationsReducer,
    accountInformationReducer,
    reissueReducer,
    transactionReducer
});

export default rootReducer
