import React from "react";

const AlertNegativeFare = (props) => (
    <div className="container-section-header">
        <div className="alert alert-warning">
            <h2>{props.message}</h2>
            <h2>NO SE PUEDE REMITIR TARIFAS EN NEGATIVO!</h2>
            <label>Por favor contacte a su supervisor para continuar con la remisión o realice una nueva búsqueda</label>
        </div>
    </div>
);

export default  AlertNegativeFare;