import axios from 'axios';
import $ from 'jquery';
import {CHANGE_GDS, CHANGE_PNR, LOADING, ON_FAIL_BOOKING_INFO, ON_RECEIVE_BOOKING_INFO, ON_FAIL_PROVIDER_BOOKING_INFO} from "../types/bookingInformationTypes";
import { ON_RECEIVE_ACCOUNT_INFORMATION, ON_FAIL_ACCOUNT_INFORMATION, LOADING as LOADING_INFO } from '../types/accountInformationTypes';
import getBookingInformationRQ from "../transfer/bookingInformatonTransfer/getBookingInformationRQ";
import getTravelItinerarySimplified from "../transfer/bookingInformatonTransfer/getTravelItinerarySimplified";
// import res from "../util/mocks/bookingInformation-ADT_INF-JSON.json";
import res from "../util/mocks/bookingInformation.json";

const getOTAProfileReadRQ = require('../transfer/accountInformationTransfer/getOTAProfileReadRQ');
const getOTAProfileReadRS = require("../transfer/accountInformationTransfer/getOTAProfileReadRS");

export const changePNR = (pnr) => dispatch => {
    dispatch({
        type: CHANGE_PNR,
        payload: pnr
    });
};

export const changeGDS = (gds) => dispatch => {
  dispatch({
    type: CHANGE_GDS,
    payload: gds
  })
}

export const searchBookingInformation = (data) => async (dispatch, getState) => {
    dispatch({
        type: LOADING
    });

    try {
        const rq = getBookingInformationRQ(data);
        const URI = 'https://reissue-service.zdev.tech/zreissue-service/v1/air/travelItineraryRead';
        
        const res = await axios.post(URI, rq);

        if (res.data.travelItinerary != null) {
            dispatch({
                type: ON_RECEIVE_BOOKING_INFO,
                payload: getTravelItinerarySimplified(res.data)
            });
        } else {
            dispatch({ type: ON_FAIL_PROVIDER_BOOKING_INFO, payload: { errorCode: 1001 } });
        }

    }
    catch(ex){
        console.log("FAIL BOOKING INFORMATION", ex);
        dispatch({ type: ON_FAIL_BOOKING_INFO, payload: { errorCode: 1001 } });
    }
};

const URI = "https://reissue-service.zdev.tech/zeticketing-service/accounts/information";

export const accountInformation = params => async (dispatch, getState) => {
  dispatch({
    type: LOADING_INFO
  });
  try {
    const { travelItineraryRS } = getState().bookingInformationReducer;

    const res = await axios.post(URI, getOTAProfileReadRQ(params));
    if (res.data) {
      dispatch({
        type: ON_RECEIVE_ACCOUNT_INFORMATION,
        payload: getOTAProfileReadRS(res.data)
      });
    } else {
      dispatch({
        type: ON_FAIL_ACCOUNT_INFORMATION,
        payload: { errorCode: 1001 }
      });
    }
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: ON_FAIL_ACCOUNT_INFORMATION,
      payload: { errorCode: 1001 }
    });
  }
};