import React, {Component} from "react";
import {connect} from "react-redux";
import FlightEngine from "./FlightEngine";
import Recommendations from "./Recommendations";
import "./searchRecommendations.scss";
import Loading from "../../common/Loading";
import Alert from "../../common/Alert";
import AlertNegativeFare from "../../common/AlertNegativeFare";
import ContactForm from "./Contact/ContactForm";

class SearchRecommendations extends Component {

    render() {
        const { loading, errorMsg, errorMsgNegativeFare } = this.props;
        return (
            <div className="SearchRecommendations">
                <ContactForm params={this.props.params}/>
                <FlightEngine/>
                { loading ? <Loading message="Estamos buscando las mejores opciones de vuelo."/> : null}
                { errorMsg ? <Alert message={errorMsg}/> : null}
                { errorMsgNegativeFare ? <AlertNegativeFare message={errorMsgNegativeFare}/> : null}
                <Recommendations history={this.props.history}/>
            </div>
        );
    }
}

const mapStateToProps = ({recommendationsReducer}) => recommendationsReducer;

export default connect(
    mapStateToProps,null
)(SearchRecommendations);
